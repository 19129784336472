/* eslint-disable import/no-anonymous-default-export */
import { useState } from "react";
import "./Login.scss";
import { Image, Typography, Input, Button, message, Form } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as authActions from "../../redux/actions/auth";
import welcomeImg from "../../assets/welcome.jpg";
import _buildings from "../../assets/buildings.jpg";
import { motion } from "framer-motion";
import {
  PhoneOutlined,
  LockOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";

export default () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    phone: params.get("phone") || "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(true);
  const [loading, setLoading] = useState(false);

  async function onFinish() {
    if (!state.phone || !state.password) {
      message.error("Không được để trống thông tin.");
      return;
    }
    dispatch(authActions.Login(state));
  }

  return (
    <div
      className="login-container"
      style={{
        // backgroundImage: ` linear-gradient(to bottom, rgba(255,255,255,0.7) 0%,rgba(255,255,255,1) 100%), url('${_buildings}')`,

        minHeight: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        className="empty-div"
        // initial={{
        //   opacity: 0.4,
        //   scale: 0.35,
        // }}
        // transition={{ duration: 0.6 }}
        // animate={{ opacity: 1, scale: 1 }}
      >
        <Image
          src={welcomeImg}
          width={"100%"}
          style={{
            marginBottom: "20px",
          }}
          preview={false}
        />
      </div>
      <Form
        name="login"
        autoComplete="off"
        form={form}
        layout="vertical"
        onFinish={(values) => {
          onFinish(values);
        }}
        className="style_login"
      >
        <Form.Item
          name="phone"
          label="Số điện thoại đăng nhập"
          rules={[
            {
              required: true,
              message: "Hãy nhập số điện thoại",
            },
          ]}
        >
          <div className="login__input__icon">
            <PhoneOutlined className="iconLeftInput" />
            <Input
              className="login__input"
              placeholder="Đăng nhập bằng số điện thoại"
              type="text"
              size="large"
              onChange={(e) => setState({ ...state, phone: e.target.value })}
            />
          </div>
        </Form.Item>

        <Form.Item
          name="password"
          label="Mật khẩu"
          rules={[
            {
              required: true,
              message: "Hãy nhập mật khẩu ",
            },
          ]}
        >
          <div className="login__input__icon">
            <LockOutlined className="iconLeftInput" />
            <Input
              className="login__input"
              type={showPassword ? "password" : "text"}
              placeholder={"Mật khẩu"}
              size="large"
              onChange={(e) => setState({ ...state, password: e.target.value })}
            />
            <div onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? (
                <EyeInvisibleOutlined className="eyeIcon" />
              ) : (
                <EyeOutlined className="eyeIcon" />
              )}
            </div>
          </div>
        </Form.Item>

        <div className="style_buton">
          <Button
            className="login-btn"
            htmlType="submit"
            loading={loading}
            size="large"
          >
            <Typography.Text style={{ color: "#fff" }}>
              Đăng nhập
            </Typography.Text>
          </Button>
        </div>

        <div className="style_buton">
          <Button
            onClick={() => history.push("/auth/signup")}
            className="login-btn"
            loading={loading}
            size="large"
          >
            <Typography.Link style={{ color: "#fff" }}>Đăng ký</Typography.Link>
          </Button>
        </div>
      </Form>
    </div>
  );
};
