module.exports = {
    BASE_URL: 'https://api.hotrotaichinhptf.com/api/v1',
    SOCKET_SERVER_URL: 'https://api.hotrotaichinhptf.com',
    // BASE_URL: 'http://192.168.35.109:8082/api/v1',
    // SOCKET_SERVER_URL: 'http://192.168.35.109:8082'

    MONEY_LOAN: [
    20000000, 50000000, 100000000, 200000000, 500000000, 900000000
  ],
  MONTH_LOAN: [12, 24, 36, 48],
  LOAN_RATE: 0.9
};