import React, { useState } from "react";
import {
  CheckSquareOutlined,
  CreditCardOutlined,
  IdcardOutlined,
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Spin, Typography } from "antd";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
// import Overview from "./Overview"; //show overview UI
import One from "./One";
import Two from "./Two";
import Three from "./Three";
import api from "../../api";
// import Success from "./Success"; //show success UI
import { useDispatch, useSelector } from "react-redux";
import "./verify.scss";
import * as actions from "../../redux/actions/auth";

export default function Verify() {
  const router = useHistory();
  const dispatch = useDispatch();
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [token] = useState(localStorage.getItem("access_token"));
  const [loading, setLoading] = useState(false);
  const profile = useSelector((state) => state._auth.profile);

  // async function updateInitRoute(initRoute) {
  //   await api.post('/users/init', { initRoute });
  // }
  // useEffect(() => {
  //   if (step == 1) {
  //     updateInitRoute('/verify');
  //   }
  //   if (step == 4) {
  //     updateInitRoute('/vay');
  //   }
  // }, [step]);

  async function handleCallApi(params, step, verified) {
    setLoading(true);
    await api.post("/users/updateProfile", {
      ...verified,
      kyc: { ...profile?.kyc, ...params },
    });
    step == 1 &&
      (await api.put("/users/profile/avatar", { url: params.id_with_face }));

    reloadProfile();
    setLoading(false);
    setStep(0);
  }

  async function reloadProfile() {
    if (token) {
      const { data } = await api.get("/users/profile");
      dispatch(actions.initialLogin(data.data));
      dispatch({ type: "UPDATE_KYC", payload: { ...(data?.data?.kyc || {}) } });
    }
  }

  async function handleClick() {
    const param = { ...profile?.kyc };
    await api.post("/users/verify", param);
    history.push("/vay");
  }
  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div className="header-container">
        <LeftOutlined
          className="arrow-icon"
          onClick={() => (step != 0 ? setStep(0) : router.goBack())}
          style={{ fontSize: "16px" }}
        />{" "}
        <Typography.Text className="header-title">
          {step == 0
            ? "Thông tin của tôi"
            : step == 1
              ? "Thông tin cơ bản"
              : step == 2
                ? "Thông tin hồ sơ"
                : step == 3
                  ? "Thẻ ngân hàng"
                  : ""}
        </Typography.Text>
        <div style={{ height: "1px", width: " 10px" }}></div>
      </div>

      {loading ? (
        <div className="loading">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} />} />
          <Typography.Text>Đang tiến hành xét duyệt</Typography.Text>
        </div>
      ) : (
        <>
          {step == 0 && (
            // <Overview
            //   onOk={(e) => {
            //     setStep(1);
            //   }}
            // />
            <>
              <div className="myInfo">
                <div className="myInfoItem" onClick={() => setStep(1)}>
                  <div className="d-flex align-item-center">
                    <IdcardOutlined
                      style={{
                        fontSize: "24px",
                        marginRight: "16px",
                        borderRadius: "50%",
                        padding: "8px",
                        backgroundColor: "blueviolet",
                        color: "white",
                      }}
                    />
                    <div className="info">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p style={{ minWidth: "160px" }}>Thông tin cá nhân</p>
                      </div>
                      <span>Dữ liệu về bản thân</span>
                    </div>
                  </div>
                  <RightOutlined />

                  <div style={{ position: "absolute", bottom: "0", right: "0" }}>
                    <span
                      style={{
                        fontSize: "11px",
                        padding: "1px",
                        color: profile?.verifiedPersonalInfo ? "green" : "red",
                        borderRadius: "4px",
                      }}
                    >
                      {profile?.verifiedPersonalInfo ? "Đã hoàn thành" : "Chưa hoàn thành"}
                    </span>
                  </div>
                </div>
                <div className="myInfoItem" onClick={() => setStep(2)}>
                  <div className="d-flex align-item-center">
                    <IdcardOutlined
                      style={{
                        fontSize: "24px",
                        marginRight: "16px",
                        borderRadius: "50%",
                        padding: "8px",
                        backgroundColor: "red",
                        color: "white",
                      }}
                    />
                    <div className="info">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p style={{ minWidth: "160px" }}>Thông tin liên hệ</p>
                      </div>
                      <span>Thông tin công việc và liên hệ người thân</span>
                    </div>
                  </div>
                  <RightOutlined />
                  <div style={{ position: "absolute", bottom: "0", right: "0" }}>
                    <span
                      style={{
                        fontSize: "11px",
                        padding: "1px",
                        color: profile?.verifiedContactInfo ? "green" : "red",
                        borderRadius: "4px",
                      }}
                    >
                      {profile?.verifiedContactInfo ? "Đã hoàn thành" : "Chưa hoàn thành"}
                    </span>
                  </div>
                </div>
                <div className="myInfoItem" onClick={() => setStep(3)}>
                  <div className="d-flex align-item-center">
                    <CreditCardOutlined
                      style={{
                        fontSize: "24px",
                        marginRight: "16px",
                        borderRadius: "50%",
                        padding: "8px",
                        backgroundColor: "green",
                        color: "white",
                      }}
                    />
                    <div className="info">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p style={{ minWidth: "160px" }}>Tài khoản cá nhân</p>
                      </div>
                      <span>Nhập chính xác tài khoản ngân hàng</span>
                    </div>
                  </div>
                  <RightOutlined />

                  <div style={{ position: "absolute", bottom: "0", right: "0" }}>
                    <span
                      style={{
                        fontSize: "11px",
                        padding: "1px",
                        color: profile?.verifiedBankInfo ? "green" : "red",
                        borderRadius: "4px",
                      }}
                    >
                      {profile?.verifiedBankInfo ? "Đã hoàn thành" : "Chưa hoàn thành"}
                    </span>
                  </div>
                </div>
              </div>
              <p className="noteUser">
                Tôi cam đoan những thông tin trên là chính xác và chịu hoàn toàn
                mọi trách nhiệm nếu có bất cứ sai sót nào về nội dung thông tin
                đã điền
              </p>
              <p
                className={`button ${profile?.verifiedPersonalInfo &&
                  profile?.verifiedContactInfo &&
                  profile?.verifiedBankInfo
                  ? ""
                  : "disabled_custom"
                  }`}
                onClick={() => handleClick()}
              >
                Vay ngay
              </p>
            </>
          )}
          {step == 1 && (
            <One
              handleCallApi={handleCallApi}
              profile={profile?.kyc}
              isVerified={profile?.verifiedPersonalInfo}
            />
          )}
          {step == 2 && (
            <Two
              handleCallApi={handleCallApi}
              profile={profile?.kyc}
              isVerified={profile?.verifiedContactInfo}
            />
          )}
          {step == 3 && (
            <Three
              handleCallApi={handleCallApi}
              profile={profile?.kyc}
              isVerified={profile?.verifiedBankInfo}
            />
          )}
          {/* {step == 4 && <Success />} */}
        </>
      )
      }
    </motion.div >
  );
}
