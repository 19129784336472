// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body {
  display: flex !important;
  justify-items: center !important;
  text-align: center !important;
  padding: 20px;
}

.body-title {
  font-size: 25px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
}

.body-des {
  font-size: 16px !important;
}

.zalo-container {
  text-align: center;
  margin-top: 20px;
}

.zalo {
  background: 10px 10px 10 rgba(0, 0, 0, 0.2) !important;
  width: 60% !important;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 0 16px;
}

.back-btn {
  border: 1px var(--border-color) !important;
  background: #db0016 !important;
  border-radius: 12px !important;
  height: 50px !important;
  color: white !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  width: 100%;
}

.header {
  padding: 0px;
}`, "",{"version":3,"sources":["webpack://./src/screens/CSKH/cskh.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;EACA,gCAAA;EACA,6BAAA;EACA,aAAA;AACJ;;AAEA;EACI,0BAAA;EACA,2BAAA;EACA,yBAAA;AACJ;;AAEA;EACI,0BAAA;AACJ;;AAGA;EACI,kBAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,sDAAA;EACA,qBAAA;AAAJ;;AAIA;EACI,aAAA;EACA,uBAAA;EACA,eAAA;AADJ;;AAIA;EACI,0CAAA;EACA,8BAAA;EACA,8BAAA;EACA,uBAAA;EACA,uBAAA;EACA,0BAAA;EACA,2BAAA;EACA,WAAA;AADJ;;AAIA;EACI,YAAA;AADJ","sourcesContent":[".body {\n    display: flex !important;\n    justify-items: center !important;\n    text-align: center !important;\n    padding: 20px;\n}\n\n.body-title {\n    font-size: 25px !important;\n    font-weight: 600 !important;\n    text-transform: uppercase;\n}\n\n.body-des {\n    font-size: 16px !important;\n\n}\n\n.zalo-container {\n    text-align: center;\n    margin-top: 20px;\n}\n\n.zalo {\n    background: 10px 10px 10 rgb(0 0 0 /0.2) !important;\n    width: 60% !important;\n\n}\n\n.footer {\n    display: flex;\n    justify-content: center;\n    padding: 0 16px;\n}\n\n.back-btn {\n    border: 1px var(--border-color) !important;\n    background: #db0016 !important;\n    border-radius: 12px !important;\n    height: 50px !important;\n    color: white !important;\n    font-size: 20px !important;\n    font-weight: 500 !important;\n    width: 100%;\n}\n\n.header {\n    padding: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
