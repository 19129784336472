import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { LeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { Typography, Empty, Divider, Modal, Table, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import api from '../../api';
import moment from 'moment';
import { ContractForm } from '../../components';
import { useSelector } from 'react-redux';
import { LOAN_RATE } from '../../utils/constant';
import { currencyFormatVND } from '../../utils/CurrencyFormat';
import ContractFormNew from '../../components/ConstractFormNew';
export default function MyContract() {
  const history = useHistory();
  const { profile } = useSelector((state) => state._auth);
  const [contract, setContract] = useState({});
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState(false)

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await api.get('/contracts');
      if (data.data.length > 0) setContract(data.data[0]);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await api.get("/users/profile");
      if (data.data.contractImageUrl !== null) setImage(data.data.contractImageUrl);
    })();
  }, []);
  const [showDetail, setShowDetail] = useState(false);

  const handlePage = () => {
    history.push('/contracts')
  }
  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '35px 15px 10px',
          borderBottom: '1px solid #eee',
          background: '#db0016'
        }}
      >
        <motion.div
          whileTap={{ scale: 0.95, x: -10 }}
          onClick={() => history.replace('/')}
          style={{ padding: 0 }}
        >
          <LeftOutlined style={{ fontSize: 25, color: '#fff' }} />
        </motion.div>
        <Typography.Text strong style={{ fontWeight: 700, fontSize: 20, color: '#fff' }}>
          Thông tin hợp đồng
        </Typography.Text>
        <div></div>
      </div>
      <div
        style={{
          padding: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '10px 15px'
        }}
      >
        {loading ? (
          <div
            style={{
              height: '80vh',
              width: '100vw',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              background: 'rgba(0,0,0,0.07)',
            }}
          >
            <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} />} />
            <Typography.Text>Đang tải dữ liệu...</Typography.Text>
          </div>
        ) : (
          <>
            {!contract?._id ? (
              <>
                <Empty description="Bạn chưa có khoản vay nào" />
                <motion.div
                  whileTap={{ opacity: 0.4, scale: 0.97 }}
                  style={{
                    background: '#db0016',
                    width: '70%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 10,
                    marginTop: 30,
                    padding: 5,
                  }}
                  onClick={() => history.push('/vay')}
                >
                  <Typography.Text
                    style={{ color: '#fff', fontWeight: 700, fontSize: 17 }}
                  >
                    Đăng ký ngay
                  </Typography.Text>
                </motion.div>
              </>
            ) : (
              !contract?.signature_capture ? (
                handlePage()
              ) : (
                <ContractFormNew contract={contract} profile={profile} />
              )
            )
            }
          </>
        )}
      </div>
    </motion.div>
  );
}
const ModalDetailPayment = ({ visible, onCancel, times, amount, created_at }) => {
  const array = [...new Array(times)].map((item, index) => ({
    index: index + 1,
    amount: amount / times + amount * LOAN_RATE / 100,
    period: `${moment(created_at).add(index + 1, 'month').format("DD-MM-YYYY")}`,
  }));
  const columns = [
    {
      title: 'Kỳ',
      dataIndex: 'index',
      key: 'index',
      render: (text) => <Typography.Text>Kỳ thứ {text}</Typography.Text>,
    },
    {
      title: 'Số tiền',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => (
        <Typography.Text strong>
          {currencyFormatVND(Math.ceil(text))}
        </Typography.Text>
      ),
    },
    {
      title: 'Ngày đóng',
      dataIndex: 'period',
      key: 'period',
      render: (text) => <Typography.Text strong>{text}</Typography.Text>,
    },
  ];
  return (
    <Modal
      visible={visible}
      onOk={onCancel}
      cancelButtonProps={{ style: { display: 'none' } }}
      closeIcon={() => <></>}
      title={'Chi tiết trả nợ'}
    >
      <div style={{ maxHeight: 350, overflowY: 'scroll' }}>
        <Table dataSource={array} columns={columns} pagination={false} />
      </div>
    </Modal>
  );
};
