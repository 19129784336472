import React, { useEffect, useState } from 'react';
import './cskh.scss';
import cskh from '../../assets/cskh.png';
import { Image, Typography, Button } from 'antd';
import zalo from '../../assets/zalo.png';
import telegramIcon from '../../assets/telegram.png';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import api from '../../api';
import isCorrectNumberPhone from '../../utils/isCorrectNumberPhone'

export default function Cskh() {
  const router = useHistory();
  function getOS() {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;
    console.log('platform: ', platform);

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }

  function callToHotline() {
    systemConfig?.supportUrl && window.open(`tel:${systemConfig?.supportUrl}`)
  }
  async function zlcskh() {
    // const os = getOS();
    // const { data } = await api.get('/users/sign-zalo');
    // window.open(`https://t.me/CSKHKBKOOKMIN111`);
    // if (isCorrectNumberPhone(data.data.supporter)) {
    //   if (os === 'Windows') {
    //     window.open(`https://zalo.me/${data.data.supporter}`)
    //   } else {
    //     window.location.assign(`https://zalo.me/${data.data.supporter}`);
    //   }
    // } else {
    //   let idFB = data.data.supporter
    //   // window.location.assign(
    //   //   `fb://${os == 'iOS' ? 'profile' : 'page'}/${idFB ? '?id=' + idFB : data.data.supporter.phone}`
    //   // );
    //   if (os === 'Windows') {
    //     window.open(`https://facebook.com/` + idFB)
    //   } else {
    //     window.location.assign(
    //       `fb://${os == 'iOS' ? 'profile/' + idFB : 'page/' + idFB}`
    //     );
    //   }
    // }
  }
  const [systemConfig, setSystemConfig] = useState({})
  async function getSystemConfig() {
    const { data } = await api.get("/systemConfiguration/getSystemConfig");
    setSystemConfig(data.data)
  }
  useEffect(() => {
    getSystemConfig()
  }, [])
  return (
    <div className="container">
      <div className="header-container">
        <div />
        <Typography.Text
          strong
          style={{ fontWeight: 700, fontSize: 18, color: '#fff' }}
        >
          CSKH
        </Typography.Text>
        <div></div>
      </div>
      <div className="header">
        <Image src={cskh} preview={false} className="head-img" />
      </div>
      <div className="body">
        <div>
          <Typography.Text className="body-title">Chăm sóc khách hàng</Typography.Text>
          <br />
          <Typography.Text className="body-des">
            Thời gian làm viêc: 08:00 đến 19:30
          </Typography.Text>
        </div>
      </div>
      <div className="footer">
        <a
          href={systemConfig?.supportUrl ? `tel:${systemConfig?.supportUrl}` : undefined}
          target="_blank"
          style={{ width: "100%" }}
          rel="noreferrer"
        >
          <Button className="back-btn">
            LIÊN HỆ HOTLINE
          </Button>
        </a>

      </div>
    </div>
  );
}
