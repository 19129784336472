// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.head-text {
  font-size: 25px !important;
  font-weight: 500 !important;
  align-self: center !important;
}

.head-text-container {
  text-align: center !important;
  padding: 25px 10px !important;
}

.loading {
  height: 80vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.07);
  position: absolute;
}

.item-box {
  background: linear-gradient(180deg, rgba(0, 132, 69, 0.35) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;
  padding: 20px 10px;
  border: 1px solid var(--border-color);
  border-bottom-width: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.warn-icon {
  font-size: 25px !important;
  font-weight: 900 !important;
  color: red !important;
}

.exclamation-icon {
  font-size: 25px !important;
  font-weight: 900 !important;
  color: #00A888 !important;
}`, "",{"version":3,"sources":["webpack://./src/screens/History/history.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,2BAAA;EACA,6BAAA;AACJ;;AACA;EACI,6BAAA;EACA,6BAAA;AAEJ;;AAAA;EACI,YAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,+BAAA;EACA,kBAAA;AAGJ;;AADA;EACI,2FAAA;EACA,mBAAA;EACA,kBAAA;EACA,qCAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AAIJ;;AAFA;EACI,0BAAA;EACF,2BAAA;EACA,qBAAA;AAKF;;AAHA;EACI,0BAAA;EACA,2BAAA;EACA,yBAAA;AAMJ","sourcesContent":[".head-text{\n    font-size: 25px !important;\n    font-weight: 500 !important ;\n    align-self: center !important;\n}\n.head-text-container{\n    text-align: center !important;\n    padding: 25px 10px !important;\n}\n.loading{\n    height: 80vh;\n    width: 100vw;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    background: rgba(0,0,0,0.07);\n    position: absolute;\n}\n.item-box{\n    background: linear-gradient(180deg, rgba(0, 132, 69, 0.35) 0%, rgba(255, 255, 255, 0) 100%);\n    border-radius: 10px;\n    padding:20px 10px;\n    border: 1px solid var(--border-color);\n    border-bottom-width: 1;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n}\n.warn-icon{\n    font-size: 25px !important;\n  font-weight: 900 !important ;\n  color: red !important;\n}\n.exclamation-icon{\n    font-size: 25px !important;\n    font-weight: 900 !important ;\n    color: #00A888 !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
